import {Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CertificateService, ClientService, OrdersService} from '../../_services';
import {Certificate, Client, Order} from '../../_models';
import {Location} from '@angular/common';

@Component({
  selector: 'app-clients-view',
  templateUrl: './clients-view.component.html',
  styleUrls: ['./clients-view.component.scss']
})
export class ClientsViewComponent implements OnInit {
  private long: number;
  orders: Order[];
  client: Client;
  filteredOrders: Order[];
  certificates: Certificate[];
  // Vector tip de client
  clientTypeArray = [
    {value: -1, text: 'Toti'},
    {value: 0, text: 'PF'},
    {value: 1, text: 'PJ-EU'},
    {value: 2, text: 'PJ-NonEU'}
  ];

  // Vector status comanda
  statusOrderArray = [
    {value: 0, text: 'Aprobată'},
    {value: 1, text: 'Duplicată'},
    {value: 2, text: 'Respinsă'},
    {value: 3, text: 'Eroare'}
  ];
  sortTIMESTAMP = false;
  sortORDER = true;
  sortAction = false;
  sortCertificate = false;


  constructor(private router: Router,
              private orderService: OrdersService,
              private clientService: ClientService,
              private resolver: ComponentFactoryResolver,
              private certificateService: CertificateService,
              private _location: Location) {

  }

  ngOnInit() {
    this.getClientById(localStorage.getItem('viewClientId'));
    this.getCertificates();
  }


  backClicked() {
    this._location.back();
  }

  getCertificates() {
    this.certificateService.getCertificates()
      .subscribe(data => {
        this.certificates = data;
      });
  }

  getOrders() {
    this.orderService.getOrders()
      .subscribe(data => {
        this.orders = data;
        this.filteredOrders = this.filterOrders(this.client.Id);
      });
  }

  getClientById(id) {
    this.clientService.getClientById(id)
      .subscribe(data => {
        this.client = data;
        console.log(data);
        this.getOrders();
      });
  }

  // Filtrari comenzi
  filterOrders(id: number) {
    return this.orders.filter(order =>
      order.Client_id == id);
  }

  viewOrder(order: Order): void {
    localStorage.removeItem('viewOrderId');
    localStorage.setItem('viewOrderId', order.Id.toString());
    this.router.navigate(['admin/orders-view']);
  }

  clientType(type: number) {
    for (const item of this.clientTypeArray) {
      if (type === item.value) {
        return item.text;
      }
    }
  }

  formatedTimestamp(timestamp: number) {
    if (timestamp !== undefined) {
      const timestampToString = timestamp.toString();
      return timestampToString.slice(0, 4)
        + '/' + timestampToString.slice(4, 6)
        + '/' + timestampToString.slice(6, 8)
        + ' ' + timestampToString.slice(8, 10)
        + ':' + timestampToString.slice(10, 12)
        + ':' + timestampToString.slice(12, 14);
    }
  }

  statusOrder(status: number) {
    for (const item of this.statusOrderArray) {
      if (status === item.value) {
        return item.text;
      }
    }
  }

  certificate(id: number) {
    for (const item of this.certificates) {
      if (id === item.Id) {
        return item.Name;
      }
    }
  }

  sortType(sort: string) {
    if (sort === 'TIMESTAMP' && this.sortTIMESTAMP === false) {
      this.sortTIMESTAMP = true;
      this.filteredOrders = this.filteredOrders.sort(this.sortByOrderTIMESTAMPAsc);
    } else if (sort === 'TIMESTAMP' && this.sortTIMESTAMP === true) {
      this.sortTIMESTAMP = false;
      this.filteredOrders = this.filteredOrders.sort(this.sortByOrderTIMESTAMPDesc);
    }
    if (sort === 'ORDER' && this.sortORDER === false) {
      this.sortORDER = true;
      this.filteredOrders = this.filteredOrders.sort(this.sortByOrderORDERdAsc);
    } else if (sort === 'ORDER' && this.sortORDER === true) {
      this.sortORDER = false;
      this.filteredOrders = this.filteredOrders.sort(this.sortByOrderORDERDesc);
    }
    if (sort === 'action' && this.sortAction === false) {
      this.sortAction = true;
      this.filteredOrders = this.filteredOrders.sort(this.sortByOrderActionAsc);
    } else if (sort === 'action' && this.sortAction === true) {
      this.sortAction = false;
      this.filteredOrders = this.filteredOrders.sort(this.sortByOrderActionDesc);
    }
    if (sort === 'certificate' && this.sortCertificate === false) {
      this.sortCertificate = true;
      this.filteredOrders = this.filteredOrders.sort(this.sortByOrderCertificateAsc);
    } else if (sort === 'certificate' && this.sortCertificate === true) {
      this.sortCertificate = false;
      this.filteredOrders = this.filteredOrders.sort(this.sortByOrderCertificateDesc);
    }

  }

  sortByOrderTIMESTAMPAsc(c1: Order, c2: Order) {
    return (c1.OrdersHistories[0].TIMESTAMP) - (c2.OrdersHistories[0].TIMESTAMP);
  }

  sortByOrderTIMESTAMPDesc(c1: Order, c2: Order) {
    return (c2.OrdersHistories[0].TIMESTAMP) - (c1.OrdersHistories[0].TIMESTAMP);
  }

  sortByOrderORDERdAsc(c1: Order, c2: Order) {
    return (c1.ORDER1) - (c2.ORDER1);
  }

  sortByOrderORDERDesc(c1: Order, c2: Order) {
    return (c2.ORDER1) - (c1.ORDER1);
  }

  sortByOrderActionAsc(c1: Order, c2: Order) {
    return (parseInt(c1.OrdersHistories[0].ACTION, 10)) - (parseInt(c2.OrdersHistories[0].ACTION, 10));
  }

  sortByOrderActionDesc(c1: Order, c2: Order) {
    return (parseInt(c2.OrdersHistories[0].ACTION, 10)) - (parseInt(c1.OrdersHistories[0].ACTION, 10));
  }

  sortByOrderCertificateAsc(c1: Order, c2: Order) {
    return (c1.Certificate_Id) - (c2.Certificate_Id);
  }

  sortByOrderCertificateDesc(c1: Order, c2: Order) {
    return (c2.Certificate_Id) - (c1.Certificate_Id);
  }
}
