import {Injectable} from '@angular/core';
import {Globals} from '../../globals';
import {HttpClient} from '@angular/common/http';
import {Client, Order} from '../_models';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient) {
  }

  getClients() {
    return this.http.get<Client[]>(Globals.URL + 'clients');
  }

  getClientById(id: number) {
    return this.http.get<Client>(Globals.URL + 'clients/' + id);
  }

  deleteClient(id: number) {
    return this.http.delete(Globals.URL + 'clients/' + id);
  }
  createClient(client: Client) {
    return this.http.post(Globals.URL + 'clients', client);
  }
}
