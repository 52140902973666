import {ToastrManager} from 'ng6-toastr-notifications';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CertificateService} from '../../_services';
import {Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {CertificateOptions} from '../../_models';

@Component({
  selector: 'app-certificate-add',
  templateUrl: './certificate-add.component.html',
  styleUrls: ['./certificate-add.component.scss']
})
export class CertificateAddComponent implements OnInit {


  certificateForm: FormGroup;
  certificateId: string;
  submitted = false;
  errorData = [];
  certificateOptionsArray = [];
  certificateOptions: CertificateOptions[];

  constructor(private formBuilder: FormBuilder,
    private certificateService: CertificateService,
    private router: Router,
    public toastr: ToastrManager) {
  }

  ngOnInit() {
    this.certificateId = localStorage.getItem('editCertificateId');
    this.getCertificateOptions();
    if (this.certificateId !== null) {
      this.initEditForm();
      this.getCertificateById(this.certificateId);
    } else {
      this.initAddForm();
    }

  }

  // Initialize form
  initEditForm() {
    this.certificateForm = this.formBuilder.group({
      Id: [],
      Name: ['', Validators.required],
      Support: ['', Validators.required],
      Price1: ['', [Validators.required]],
      Price2: ['', [Validators.required]],
      Price3: ['', Validators.required],
      Certificate_options: [],
      IsDeleted: [false],
      CreatedAt: [],
      UpdatedAt: [],
    });
  }

  initAddForm() {
    this.certificateForm = this.formBuilder.group({
      Name: ['', Validators.required],
      Support: ['', Validators.required],
      Price1: ['', [Validators.required]],
      Price2: ['', [Validators.required]],
      Price3: ['', Validators.required],
      Certificate_options: [],
    });
  }


  onChange(id: number, isChecked: boolean) {
    if (isChecked) {
      this.certificateOptionsArray.push(id);
    } else {
      const index = this.certificateOptionsArray.indexOf(id);
      this.certificateOptionsArray.splice(index, 1);
    }
  }

  getCertificateOptions() {
    this.certificateService.getCertificateOptions().subscribe(data => {
      this.certificateOptions = data;
    });
  }

  getCertificateById(certificateId) {
    this.certificateService.getCertificateById(certificateId)
      .subscribe(data => {
        this.certificateForm.setValue(data);
        this.certificateOptionsArray = data.Certificate_options.split(',').map(Number);
      });
  }

  //  form getters
  get Name() {
    return this.certificateForm.get('Name');
  }

  get Support() {
    return this.certificateForm.get('Support');
  }

  get Price1() {
    return this.certificateForm.get('Price1');
  }

  get Price2() {
    return this.certificateForm.get('Price2');
  }

  get Price3() {
    return this.certificateForm.get('Price3');
  }

  get Certificate_options() {
    return this.certificateForm.get('Certificate_options');
  }

  get CreatedAt() {
    return this.certificateForm.get('CreatedAt');
  }

  get IsDeleted() {
    return this.certificateForm.get('IsDeleted');
  }

  selectOption(item) {
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.certificateForm.invalid) {
      return;
    } else {
      if (this.certificateId !== null) {
        this.certificateForm.controls['Certificate_options'].setValue(this.certificateOptionsArray.toString());
        this.certificateService.updateCertificate(this.certificateForm.value)
          .pipe(first())
          .subscribe(data => {
            localStorage.removeItem('editCertificateId');
            document.getElementById('closeAddCertificateModal').click();
            this.router.navigateByUrl('admin', { skipLocationChange: true }).then(() =>
              this.router.navigate(['admin/certificate-list']));
            this.toastr.successToastr('Certificatul a fost modificat', 'Succes!');
          },
            error => {
              this.errorData = [];
              this.errorData.push(error);
              return;
            });
      } else {
        this.certificateForm.controls['Certificate_options'].setValue(this.certificateOptionsArray.toString());
        this.certificateService.createCertificate(this.certificateForm.value)
          .subscribe(data => {
            document.getElementById('closeAddCertificateModal').click();
            this.router.navigateByUrl('admin', { skipLocationChange: true }).then(() =>
              this.router.navigate(['admin/certificate-list']));
            this.toastr.successToastr('Certificatul a fost adăugat', 'Succes!');
          },
            error => {
              this.errorData = [];
              this.errorData.push(error);
              return;
            });
      }
    }
  }
}
