import {Injectable} from '@angular/core';

@Injectable()
export class Globals {
  static mesageSuccess = 'Lista a fost actualizata!';
  static mesageError = 'Eroare !';
  static URL = 'http://andreiciupu.ro/testapi/api/';
  static URL3 = 'http://andreiciupu.ro/testapi/';
  // static URL = 'http://localhost:3000/';
  static URL2 = 'https://www.activare3dsecure.ro/teste3d/cgi-bin/';
  static token = true; // default is false
  static TERMINAL = 60001501;
  static BACKREF = Globals.URL + 'Reply?TERMINAL={TERMINAL}&TRTYPE={TRTYPE}&ORDER={ORDER}' +
    '&AMOUNT={AMOUNT}&CURRENCY={CURRENCY}&DESC={DESC}&ACTION={ACTION}&RC={RC}&MESSAGE={MESSAGE}' +
    '&RRN={RRN}&APPROVAL={APPROVAL}&TIMESTAMP={TIMESTAMP}&NONCE={NONCE}&P_SIGN={P_SIGN}&BACKREF={BACKREF}';
  static BACKREF2 = Globals.URL + 'Reply';
static MERCH_NAME = 'TRANS SPED SRL';
static MERCH_URL = 'http://cloudsignature.transsped.ro';
static MERCHANT = '0000000' + Globals.TERMINAL.toString();
// static EMAIL = 'OFFICE@TRANSSPED.RO';
static EMAIL = 'IUREACOSMIN@YAHOO.COM';
}
