import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../globals';
import {Certificate, CertificateOptions} from '../_models';

@Injectable({
  providedIn: 'root'
})
export class CertificateService {
  constructor(private http: HttpClient) {
  }

  getCertificates() {
    return this.http.get<Certificate[]>(Globals.URL + 'certificates');
  }


  getCertificateById(id: number) {
    return this.http.get<Certificate>(Globals.URL + 'certificates/' + id);
  }

  deleteCertificate(id: number) {
    return this.http.delete(Globals.URL + 'certificates/' + id);
  }

  updateCertificate(certificate: Certificate) {
    return this.http.put(Globals.URL + 'certificates/' + certificate.Id, certificate);
  }

  createCertificate(certificate: Certificate) {
    return this.http.post(Globals.URL + 'certificates', certificate);
  }

  createCertificateOption(option: CertificateOptions) {
    return this.http.post(Globals.URL + 'CertificateOptions', option);
  }

  getCertificateOptions() {
    return this.http.get<CertificateOptions[]>(Globals.URL + 'CertificateOptions');
  }

  deleteCertificateOption(id: number) {
    return this.http.delete(Globals.URL + 'CertificateOptions/' + id);
  }

  getCertificateOptionById(id: number) {
    return this.http.get<Certificate>(Globals.URL + 'CertificateOptions/' + id);
  }

  updateCertificateOption(option: CertificateOptions) {
    return this.http.put(Globals.URL + 'CertificateOptions/' + option.Id, option);
  }
}
