import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Globals } from '../../globals';
import { HttpClient } from '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contactUs.component.html',
  styleUrls: ['./contactUs.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactUsForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private http: HttpClient, private toastr: ToastrManager) {
  }

  ngOnInit() {
    this.initContactUsForm();
  }

  initContactUsForm() {
    this.contactUsForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
      email: ['', [Validators.email, Validators.required]],
      phonenumber: ['', [Validators.required, Validators.pattern('^[0-9 +]*$')]],
      contactmsg: ['', Validators.required]
    });
  }

  get name() {
    return this.contactUsForm.get('name');
  }

  get email() {
    return this.contactUsForm.get('email');
  }

  get phonenumber() {
    return this.contactUsForm.get('phonenumber');
  }

  get contactmsg() {
    return this.contactUsForm.get('contactmsg');
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.contactUsForm.invalid) {
      return;
    } else {
      document.getElementById('closeContactUsModal').click();
      this.sendEmail(this.contactUsForm.value)
        .subscribe(
          data3 => {
            this.toastr.successToastr('Email successfully sent', 'Success!');
          },
          error => {
            this.toastr.errorToastr('Email was not sent', 'Error!');
          });
    }
  }

  sendEmail(form) {
    return this.http.post(Globals.URL + 'Email/SendEmail', {
      To: Globals.EMAIL,
      Subject: 'contact Transsped',
      // tslint:disable-next-line:max-line-length
      Body: 'De la ' + form.name + '<br> cu email-ul ' + form.email + '<br>numarul: ' + form.phonenumber + '<br>mesajul: ' + form.contactmsg,
      isEncoded: false
    });
  }
}
