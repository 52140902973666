import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminComponent} from './admin.component';
import {AuthGuard} from './_guards';
import {OrdersListComponent} from './orders/orders-list/orders-list.component';
import {OrdersViewComponent} from './orders/orders-view/orders-view.component';
import {ClientsViewComponent} from './clients/clients-view/clients-view.component';
import {ClientsListComponent} from './clients/clients-list/clients-list.component';
import {CertificateListComponent} from './certificates/certificate-list/certificate-list.component';
import {CertificateAddComponent} from './certificates/certificate-add/certificate-add.component';
import {OptionEditComponent} from './certificates/option-edit/option-edit.component';
import {ClientsEmailsComponent} from './clients/clients-emails/clients-emails.component';
import {SettingsComponent} from './settings/settings.component';


const adminRoutes: Routes = [
  {
    path: 'admin',
    redirectTo: 'admin/orders-list',
    pathMatch: 'full'
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    component: AdminComponent,
    children: [
      {
        path: 'certificate-list',
        component: CertificateListComponent
      },
      {
        path: 'certificate-add',
        component: CertificateAddComponent
      },
      {
        path: 'clients-list',
        component: ClientsListComponent
      },
      {
        path: 'clients-emails',
        component: ClientsEmailsComponent
      },
      {
        path: 'clients-view',
        component: ClientsViewComponent
      },
      {
        path: 'orders-list',
        component: OrdersListComponent
      },
      {
        path: 'orders-view',
        component: OrdersViewComponent
      },
      {
        path: 'option-edit',
        component: OptionEditComponent
      },
      {
        path: 'settings',
        component: SettingsComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(adminRoutes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {
}
