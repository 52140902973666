import {Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CertificateService, ClientService, OrdersService} from '../../_services';
import {Certificate, Client, Order} from '../../_models';
import {formatDate, Location} from '@angular/common';

@Component({
  selector: 'app-orders-view',
  templateUrl: './orders-view.component.html',
  styleUrls: ['./orders-view.component.scss']
})
export class OrdersViewComponent implements OnInit {

  constructor(private router: Router,
              private orderService: OrdersService,
              private clientService: ClientService,
              private certificateService: CertificateService,
              private resolver: ComponentFactoryResolver,
              private _location: Location) {

  }

  // Vector tip de client
  clientTypeArray = [
    {value: -1, text: 'Toti'},
    {value: 0, text: 'PF'},
    {value: 1, text: 'PJ-EU'},
    {value: 2, text: 'PJ-NonEU'}
  ];

  // Vector status comanda
  statusOrderArray = [
    {value: 0, text: 'aprobată'},
    {value: 1, text: 'duplicată'},
    {value: 2, text: 'respinsă'},
    {value: 3, text: 'eroare'}
  ];

  order: Order;
  client: Client;
  certificate: Certificate;

  ngOnInit() {
    this.getOrderById(localStorage.getItem('viewOrderId'));

  }

  backClicked() {
    this._location.back();
  }


  // formatari
  formatedTimestamp(timestamp: number) {
    // const timestampToString = timestamp.toString();
    const timestampToString = String(timestamp);

    return timestampToString.slice(0, 4)
      + '/' + timestampToString.slice(4, 6)
      + '/' + timestampToString.slice(6, 8)
      + ' ' + timestampToString.slice(8, 10)
      + ':' + timestampToString.slice(10, 12)
      + ':' + timestampToString.slice(12, 14);
  }

  clientType(type: number) {
    for (const item of this.clientTypeArray) {
      if (type === item.value) {
        return item.text;
      }
    }
  }

  statusOrder(status: number) {
    for (const item of this.statusOrderArray) {
      if (status === item.value) {
        return item.text;
      }
    }
  }

  certificatePeriod(price: number) {
    if (this.certificate) {
      if (price === this.certificate.Price1) {
        return '1 an';
      } else if (price === this.certificate.Price2) {
        return '2 ani';
      } else if (price === this.certificate.Price3) {
        return '3 ani';
      } else {
        return 'nu stiu perioada verifica pret';
      }
    }
  }

  // aducatori de informatii
  getOrderById(orderID) {
    this.orderService.getOrderById(orderID)
      .subscribe(data => {
        this.order = data;
        this.getClientById(data.Client_id);
        this.getCertificateById(data.Certificate_Id);
      });

  }

  getCertificateById(id) {
    this.certificateService.getCertificateById(id)
      .subscribe(data => {
        this.certificate = data;
      });
  }

  getClientById(id) {
    this.clientService.getClientById(id)
      .subscribe(data => {
        this.client = data;
      });
  }

  //  Anulare comanda
  revertOrder() {
    const time = formatDate(Date.now(), 'yyyyMMddhhmmss', 'en-GB', '+0000');
    const randomString = this.orderService.generateRandomString();
    if (confirm('Sunteți sigur că doriți să anulați comanda?')) {
      this.orderService.generatePSignReversal(this.order, time, randomString, 24).subscribe(
        data3 => {
          // @ts-ignore
          localStorage.setItem('data3', data3.Result);
          // @ts-ignore
          window.location.href = this.orderService.revertOrder(this.order, time, randomString, data3.Result).toString();
        },
        error => {
        });

    }
  }
}
