import { ContactUsComponent } from '../contactUs/contactUs.component';
import { CertificateOptions } from '../../admin/_models';
import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { CertificateService, OrdersService } from '../../admin/_services';
import { Certificate } from '../../admin/_models';
import { BuyCertificateComponent } from '../buyCertificate/buyCertificate.component';
import { DragScrollComponent, DragScrollModule } from 'ngx-drag-scroll';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  certificateOptions: CertificateOptions[];
  certificates: Certificate[];
  public showSpinner = false;
  checkedPrice: number;
  selectedCertificatePrice: boolean;

  @ViewChild('buyCertificate', { read: ViewContainerRef })
  entry: ViewContainerRef;
  @ViewChild('certificatescroll', { read: DragScrollComponent })
  ds: DragScrollComponent;
  @ViewChild('contactUs', { read: ViewContainerRef })
  modalentry: ViewContainerRef;

  constructor(
    private formBuilder: FormBuilder,
    private orderService: OrdersService,
    private certificateService: CertificateService,
    private router: Router,
    private resolver: ComponentFactoryResolver,
    private toastr: ToastrManager
  ) {
  }

  ngOnInit() {
    this.checkOrderNumber();
    this.showLoadingSpinner();
    this.getCertificates();
    this.getCertificateOptions();
  }

  checkOrderNumber() {

    const orderNumber = localStorage.getItem('OrderNumber');
    let action: number;
    if (orderNumber !== null) {

      this.orderService.getOrderById(parseInt(orderNumber, 10)).subscribe(data => {
        action = parseInt(data.OrdersHistories[0].ACTION, 10);
        // action = 0;
        if (action === 0) {
          this.toastr.successToastr('Transaction approved', 'Success!');
          localStorage.removeItem('OrderNumber');
        } else if (action === 1) {
          this.toastr.infoToastr('Transaction duplicated', 'Duplicate!');
          localStorage.removeItem('OrderNumber');
        } else if (action === 2) {
          this.toastr.warningToastr('Transaction rejected', 'Rejected!');
          localStorage.removeItem('OrderNumber');
        } else if (action === 3) {
          this.toastr.warningToastr('Process error', 'Error!');
          localStorage.removeItem('OrderNumber');
        }
        // else if (isNaN(action)) {
        //   this.toastr.infoToastr('nu merje wai');
        // }
      }, error => {
      });
    }

  }

  moveBack() {
    this.ds.moveLeft();
  }

  moveForward() {
    this.ds.moveRight();
  }

  showLoadingSpinner() {
    this.showSpinner = true;
  }

  hideLoadingSpinner() {
    this.showSpinner = false;
  }

  getCertificateOptions() {
    this.certificateService.getCertificateOptions().subscribe(data => {
      this.certificateOptions = data;
      this.hideLoadingSpinner();
    });
  }

  getCertificateOptionsArray(elem) {
    let CertificateOptionsArray = [];
    CertificateOptionsArray = elem.Certificate_options.split(',').map(Number);
    return CertificateOptionsArray;
  }

  getCertificates() {
    this.certificateService.getCertificates().subscribe(data => {
      this.certificates = data;
    });
  }

  buyCertificate2(certificateId) {

    if (this.selectedCertificatePrice === undefined || this.selectedCertificatePrice === false) {
      this.toastr.warningToastr('Nu ați introdus selectat nici un preț');
    } else {
      $('#buyCertificateModal').modal('show');
      localStorage.removeItem('buyCertificateId');
      localStorage.setItem('certificatePrice', this.checkedPrice.toString());
      localStorage.setItem('buyCertificateId', certificateId.toString());
      this.entry.clear();
      const factory = this.resolver.resolveComponentFactory(
        BuyCertificateComponent
      );
      const componentRef = this.entry.createComponent(factory);
    }
  }

  radioChangeHandler(event: boolean, priceType, ) {
    if (event) {
      this.checkedPrice = priceType;
      this.selectedCertificatePrice = true;
    } else {
      this.selectedCertificatePrice = false;
    }
  }

  modalContactUs() {
    this.modalentry.clear();
    const factory = this.resolver.resolveComponentFactory(ContactUsComponent);
    const componentRef = this.modalentry.createComponent(factory);
  }
}
