import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CertificateService } from '../../_services';
import { Certificate, CertificateOptions } from '../../_models';
import { CertificateAddComponent } from '../certificate-add/certificate-add.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OptionEditComponent } from '../option-edit/option-edit.component';
import { ToastrManager } from 'ng6-toastr-notifications';
import { empty } from 'rxjs';
import { isEmpty } from 'rxjs/operators';

@Component({
  selector: 'app-certificate-list',
  templateUrl: './certificate-list.component.html',
  styleUrls: ['./certificate-list.component.scss']
})
export class CertificateListComponent implements OnInit {



  filteredCertificates: Certificate[];
  certificateOption = {
    Id: 0,
    OptionName: ' ',
  };
  certificateOptions: CertificateOptions[];
  certificates: Certificate[];
  sortPrice1 = false;
  sortPrice2 = false;
  sortPrice3 = false;
  sortId = true;

  // Variabile cautare
  private _searchTerm: string;

  // Variabila optiune
  private _optionName: string;

  // verifică add or edit certificate
  operationTypeCertificate = '';

  @ViewChild('formContainer', { read: ViewContainerRef }) entry: ViewContainerRef;
  @ViewChild('editOption', { read: ViewContainerRef }) entry2: ViewContainerRef;

  constructor(private certificateService: CertificateService,
    private resolver: ComponentFactoryResolver,
    private formBuilder: FormBuilder,
    public toastr: ToastrManager) {

    this.certificateOption = new CertificateOptions();
  }

  ngOnInit() {
    this.getCertificates();
    this.getCertificateOptions();
  }

  deleteCertificate(certificate: Certificate): void {
    if (confirm('Sunteți sigur că doriți să ștergeți certificatul?')) {
      this.certificateService.deleteCertificate(certificate.Id)
        .subscribe(data => {
          this.filteredCertificates = this.filteredCertificates.filter(u => u !== certificate);
          this.toastr.successToastr('Certificatul a fost șters', 'Succes!');
        });
    }
  }

  deleteCertificateOption(option: CertificateOptions): void {
    if (confirm('Sunteți sigur că doriți să ștergeți opțiunea?')) {
      this.certificateService.deleteCertificateOption(option.Id)
        .subscribe(data => {
          this.certificateOptions = this.certificateOptions.filter(u => u !== option);
          this.toastr.successToastr('Opțiunea a fost ștearsă', 'Succes!');
        });
    }
  }

  getCertificateOptions() {
    this.certificateService.getCertificateOptions()
      .subscribe(data => {
        this.certificateOptions = data;
      });
  }

  getCertificates() {
    this.certificateService.getCertificates()
      .subscribe(data => {
        this.certificates = data;
        this.filteredCertificates = this.certificates;
      });
  }

  editCertificate(certificate: Certificate): void {
    this.operationTypeCertificate = 'Editare';
    localStorage.removeItem('editCertificateId');
    localStorage.setItem('editCertificateId', certificate.Id.toString());
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(CertificateAddComponent);
    const componentRef = this.entry.createComponent(factory);
  }

  editCertificateOption(option: CertificateOptions): void {
    localStorage.removeItem('certificateOptionEdit');
    localStorage.setItem('certificateOptionEdit', option.Id.toString());
    this.entry2.clear();
    const factory = this.resolver.resolveComponentFactory(OptionEditComponent);
    const componentRef = this.entry2.createComponent(factory);
  }


  addCertificate() {
    this.operationTypeCertificate = 'Adăugare';
    localStorage.removeItem('editCertificateId');
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(CertificateAddComponent);
    const componentRef = this.entry.createComponent(factory);
  }

  // Adaugare certificat optiuni
  createOption() {
    this.certificateOption.OptionName = this.certificateOption.OptionName.trim();

    if (this.certificateOption.OptionName) {
      this.certificateService.createCertificateOption(this.certificateOption).subscribe(data => {
        this.getCertificateOptions();
        this.toastr.successToastr('Ați introdus categoria cu succes!', 'Succes!');
        this.certificateOption.OptionName = '';
      }, err => {
        this.toastr.successToastr('Introducere nu a putut fi efectuată!', 'Eroare!');
      });
    } else {
      this.toastr.errorToastr('Nu ați introdus opțiunea', 'Eroare!');
    }
  }


  // Cautari

  get searchTerm(): string {
    return this._searchTerm;
  }

  set searchTerm(value: string) {
    this._searchTerm = value;
    this.filteredCertificates = this.searchCertificatesByName(value);
  }

  searchCertificatesByName(searchString: string) {
    if (searchString !== '') {
      return this.certificates.filter(item =>
        item.Name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1);
    } else {
      return this.certificates;
    }
  }

  // Sortari
  sortType(sort: string) {
    if (sort === 'price1' && this.sortPrice1 === false) {
      this.sortPrice1 = true;
      this.filteredCertificates = this.filteredCertificates.sort(this.sortCertificateByPrice1Asc);
    } else if (sort === 'price1' && this.sortPrice1 === true) {
      this.sortPrice1 = false;
      this.filteredCertificates = this.filteredCertificates.sort(this.sortCertificateByPrice1Desc);
    }
    if (sort === 'price2' && this.sortPrice2 === false) {
      this.sortPrice2 = true;
      this.filteredCertificates = this.filteredCertificates.sort(this.sortCertificateByPrice2Asc);
    } else if (sort === 'price2' && this.sortPrice2 === true) {
      this.sortPrice2 = false;
      this.filteredCertificates = this.filteredCertificates.sort(this.sortCertificateByPrice2Desc);
    }
    if (sort === 'price3' && this.sortPrice3 === false) {
      this.sortPrice3 = true;
      this.filteredCertificates = this.filteredCertificates.sort(this.sortCertificateByPrice3Asc);
    } else if (sort === 'price3' && this.sortPrice3 === true) {
      this.sortPrice3 = false;
      this.filteredCertificates = this.filteredCertificates.sort(this.sortCertificateByPrice3Desc);
    }
    if (sort === 'id' && this.sortId === false) {
      this.sortId = true;
      this.filteredCertificates = this.filteredCertificates.sort(this.sortCertificateByIdAsc);
    } else if (sort === 'id' && this.sortId === true) {
      this.sortId = false;
      this.filteredCertificates = this.filteredCertificates.sort(this.sortCertificateByIdDesc);
    }

  }

  sortCertificateByPrice1Asc(c1: Certificate, c2: Certificate) {
    return (c1.Price1) - (c2.Price1);
  }

  sortCertificateByPrice1Desc(c1: Certificate, c2: Certificate) {
    return (c2.Price1) - (c1.Price1);
  }

  sortCertificateByPrice2Asc(c1: Certificate, c2: Certificate) {
    return (c1.Price2) - (c2.Price2);
  }

  sortCertificateByPrice2Desc(c1: Certificate, c2: Certificate) {
    return (c2.Price2) - (c1.Price2);
  }

  sortCertificateByPrice3Asc(c1: Certificate, c2: Certificate) {
    return (c1.Price3) - (c2.Price3);
  }

  sortCertificateByPrice3Desc(c1: Certificate, c2: Certificate) {
    return (c2.Price3) - (c1.Price3);
  }

  sortCertificateByIdAsc(c1: Certificate, c2: Certificate) {
    return (c1.Id) - (c2.Id);
  }

  sortCertificateByIdDesc(c1: Certificate, c2: Certificate) {
    return (c2.Id) - (c1.Id);
  }

}
