import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SettingsService} from '../_services/settings.service';
import {ToastrManager} from 'ng6-toastr-notifications';


@Component({
  selector: 'app-settings',
  templateUrl: 'settings.component.html',
  styleUrls: ['settings.component.scss']
})
export class SettingsComponent implements OnInit {

  settingsForm: FormGroup;
  errorData = [];
  id = 0;

  constructor(private formBuilder: FormBuilder,
              private settingsService: SettingsService,
              public toastr: ToastrManager) {
  }

  ngOnInit() {
    this.getSettings();
    this.initForm();
  }

  getSettings() {
    this.settingsService.getSettings()
      .subscribe(data => {
          this.id = data[0].Id;
          this.settingsForm.setValue(data[0]);
        },
        error => {
          this.errorData = [];
          this.errorData.push(error);
          console.log(this.errorData);
          return;
        });
  }

  initForm() {
    this.settingsForm = this.formBuilder.group({
      ProformSeries: ['', Validators.required],
      ProformNumber: ['', Validators.required],
      InvoiceSeries: ['', Validators.required],
      InvoiceNumber: ['', Validators.required],
      CreatedAt: [null],
      UpdatedAt: [null],
      Id: [null],
    });
  }


  //  form getters
  get ProformSeries() {
    return this.settingsForm.get('ProformSeries');
  }

  //  form getters
  get ProformNumber() {
    return this.settingsForm.get('ProformNumber');
  }

  //  form getters
  get InvoiceSeries() {
    return this.settingsForm.get('InvoiceSeries');
  }

  //  form getters
  get InvoiceNumber() {
    return this.settingsForm.get('InvoiceNumber');
  }

  onSubmit() {

    if (this.settingsForm.invalid) {
      return;
    } else {
      if (this.id === 0) {
        this.settingsService.createSettings(this.settingsForm.value)
          .subscribe(data => {
              this.toastr.successToastr('Setarile au fost create cu succes', 'Succes!');
            },
            error => {
              this.errorData = [];
              this.errorData.push(error);
              return;
            });
      } else {
        this.settingsService.updateSettings(this.settingsForm.value)
          .subscribe(data => {
              this.toastr.successToastr('Setarile au fost modificate cu succes', 'Succes!');
            },
            error => {
              this.errorData = [];
              this.errorData.push(error);
              return;
            });
      }

    }

  }
}
