import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CertificateService } from '../../_services';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-option-edit',
  templateUrl: './option-edit.component.html',
  styleUrls: ['./option-edit.component.scss']
})
export class OptionEditComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private certificateService: CertificateService,
    private router: Router,
    public toastr: ToastrManager) {
  }

  certificateOptionForm: FormGroup;
  optionId: string;
  submitted = false;
  errorData = [];

  ngOnInit() {
    this.optionId = localStorage.getItem('certificateOptionEdit');
    this.initForm();
    if (this.optionId !== null) {
      this.getOptionById(this.optionId);
    } else {
    }

  }

  // Initialize form
  initForm() {
    this.certificateOptionForm = this.formBuilder.group({
      Id: [],
      OptionName: ['', Validators.required],
    });
  }

  getOptionById(optionId) {
    this.certificateService.getCertificateOptionById(optionId)
      .subscribe(data => {
        this.certificateOptionForm.setValue(data);
      });
  }

  //  form getters
  get Id() {
    return this.certificateOptionForm.get('Id');
  }

  get OptionName() {
    return this.certificateOptionForm.get('OptionName');
  }


  onSubmit() {
    this.submitted = true;

    // stop here if form is invalId
    if (this.certificateOptionForm.invalid) {
      return;
    } else {
      if (this.optionId.length > 0) {
        this.certificateService.updateCertificateOption(this.certificateOptionForm.value)
          .pipe(first())
          .subscribe(data => {
            localStorage.removeItem('certificateOptionEdit');
            document.getElementById('editCertificateOptionModal').click();
            this.router.navigateByUrl('admin', { skipLocationChange: true }).then(() =>
              this.router.navigate(['admin/certificate-list']));
            this.toastr.successToastr('Opțiunea a fost modificată', 'Succes!');
          },
            error => {
              this.errorData = [];
              this.errorData.push(error);
              return;
            });
      }
    }
  }
}
