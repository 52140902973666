import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { CertificateService, ClientService, OrdersService } from '../../_services';
import { Certificate, Client, Order, OrdersHistories } from '../../_models';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})
export class OrdersListComponent implements OnInit {

  // Vector status comanda
  statusOrderArray = [
    { value: 0, text: 'aprobată' },
    { value: 1, text: 'duplicată' },
    { value: 2, text: 'respinsă' },
    { value: 3, text: 'eroare' }
  ];

  // Variabile sortare
  sortTIMESTAMP = false;
  sortORDER = true;
  sortAction = false;

  // Variabile pentru inserare date
  orders: Order[];
  clients: Client[];
  filteredOrders: Order[];
  certificates: Certificate[];


  // Variabile cautare
  private _searchTerm: string;

  constructor(private router: Router,
    private orderService: OrdersService,
    private clientService: ClientService,
    private certificateService: CertificateService,
    private resolver: ComponentFactoryResolver) {

  }

  ngOnInit() {
    this.getCertificates();
    this.getOrders();
    this.getClients();

  }

  // metode formatare informatii
  formatedTimestamp(timestamp: number) {
    if (timestamp !== undefined) {
      const timestampToString = timestamp.toString();
      return timestampToString.slice(0, 4)
        + '/' + timestampToString.slice(4, 6)
        + '/' + timestampToString.slice(6, 8)
        + ' ' + timestampToString.slice(8, 10)
        + ':' + timestampToString.slice(10, 12)
        + ':' + timestampToString.slice(12, 14);
    }
  }

  statusOrder(status: number) {
    for (const item of this.statusOrderArray) {
      if (status === item.value) {
        return item.text;
      }
    }
  }

  certificate(id: number) {
    if (this.certificates !== undefined) {
      for (const item of this.certificates) {
        if (id === item.Id) {
          return item.Name;
        }
      }
    }

  }

  // Aducatori de informatii

  getCertificates() {
    this.certificateService.getCertificates()
      .subscribe(data => {
        this.certificates = data;
      });
  }

  getClients() {
    this.clientService.getClients()
      .subscribe(data => {
        this.clients = data;
      });
  }

  getOrders() {
    this.orderService.getOrders()
      .subscribe(data => {
        this.orders = data;
        this.filteredOrders = this.orders;
        this.sortType('ORDER');
      });
  }

  deleteOrder(order: Order): void {
    if (confirm('Sunteți sigur că doriți să ștergeți comanda?')) {
      this.orderService.deleteOrder(order.Id)
        .subscribe(data => {
          this.filteredOrders = this.filteredOrders.filter(u => u !== order);
        });
    }
  }

  viewOrder(order: Order): void {
    localStorage.removeItem('viewOrderId');
    localStorage.setItem('viewOrderId', order.Id.toString());
    this.router.navigate(['admin/orders-view']);
  }

  viewClient(client: Client): void {
    localStorage.removeItem('viewClientId');
    localStorage.setItem('viewClientId', client.Id.toString());
    this.router.navigate(['admin/clients-view']);
  }

  // Cautari

  get searchTerm(): string {
    return this._searchTerm;
  }

  set searchTerm(value: string) {
    this._searchTerm = value;
    this.filteredOrders = this.searchOrdersByName(value);
  }

  searchOrdersByName(searchString: string) {
    const orders3 = [];
    let clients: Client[];
    if (searchString !== '' && searchString.length > 2) {
      clients = this.clients.filter(client =>
        client.Name != null && client.Name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
        client.Surname != null && client.Surname.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
        client.CompanyName != null && client.CompanyName.toLowerCase().indexOf(searchString.toLowerCase()) !== -1);
      for (const order of this.filteredOrders) {
        for (const client of clients) {
          if (client.Id == order.Client_id) {
            orders3.push(order);
          }
        }
      }
      return orders3;
    } else {
      return this.orders;
    }
  }

  // Sortari

  sortType(sort: string) {
    if (sort === 'TIMESTAMP' && this.sortTIMESTAMP === false) {
      this.sortTIMESTAMP = true;
      this.filteredOrders = this.filteredOrders.sort(this.sortByOrderTIMESTAMPAsc);
    } else if (sort === 'TIMESTAMP' && this.sortTIMESTAMP === true) {
      this.sortTIMESTAMP = false;
      this.filteredOrders = this.filteredOrders.sort(this.sortByOrderTIMESTAMPDesc);
    }
    if (sort === 'ORDER' && this.sortORDER === false) {
      this.sortORDER = true;
      this.filteredOrders = this.filteredOrders.sort(this.sortByOrderORDERdAsc);
    } else if (sort === 'ORDER' && this.sortORDER === true) {
      this.sortORDER = false;
      this.filteredOrders = this.filteredOrders.sort(this.sortByOrderORDERDesc);
    }
    if (sort === 'action' && this.sortAction === false) {

      this.sortAction = true;
      this.filteredOrders = this.filteredOrders.sort(this.sortByOrderActionAsc);
    } else if (sort === 'action' && this.sortAction === true) {

      this.sortAction = false;
      this.filteredOrders = this.filteredOrders.sort(this.sortByOrderActionDesc);
    }

  }

  sortByOrderTIMESTAMPAsc(c1: Order, c2: Order) {
    return (c1.OrdersHistories[0].TIMESTAMP) - (c2.OrdersHistories[0].TIMESTAMP);
  }

  sortByOrderTIMESTAMPDesc(c1: Order, c2: Order) {
    return (c2.OrdersHistories[0].TIMESTAMP) - (c1.OrdersHistories[0].TIMESTAMP);
  }

  sortByOrderORDERdAsc(c1: Order, c2: Order) {
    return (c1.Id) - (c2.Id);
  }

  sortByOrderORDERDesc(c1: Order, c2: Order) {
    return (c2.Id) - (c1.Id);
  }

  sortByOrderActionAsc(c1: Order, c2: Order) {
    const test = (parseInt(c1.OrdersHistories[0].TRTYPE, 10)) - (parseInt(c2.OrdersHistories[0].TRTYPE, 10));
    return (parseInt(c1.OrdersHistories[0].TRTYPE, 10)) - (parseInt(c2.OrdersHistories[0].TRTYPE, 10));
  }

  sortByOrderActionDesc(c1: Order, c2: Order) {
    const test2 = (parseInt(c2.OrdersHistories[0].TRTYPE, 10)) - (parseInt(c1.OrdersHistories[0].TRTYPE, 10));
    return (parseInt(c2.OrdersHistories[0].TRTYPE, 10)) - (parseInt(c1.OrdersHistories[0].TRTYPE, 10));
  }


}
