export class Client {
  Id: number;
  UniqueIdentificationNumber: string;
  Type: number;
  Name: string;
  Surname: string;
  Country: string;
  Street: string;
  Number: number;
  AddressDetails: string;
  Phone: number;
  Email: string;
  ContactName: string;
  TVA_code: string;
  CUI: string;
  IBAN: string;
  Bank: string;
  CIF: string;
  CompanyName: string;
  VATCode: string;
  FiscalIdentificationNumber: string;
  IsDeleted: boolean;
  City: string;
}
