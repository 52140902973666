import { Component, AfterViewInit } from '@angular/core';

import * as $ from 'jquery';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements AfterViewInit {

  constructor() { }

  ngAfterViewInit() {
    // $(document).ready(function () {
    // });
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    onScroll();
    // Auto Active
    function onScroll() {
      const scrollPos = $(document).scrollTop();
      $('.navbar-nav a').each(function () {
        const currLink = $(this);
        const refElement = $(currLink.attr('id'));

        if (refElement.length) {

          if (refElement.position().top <= scrollPos + 85 && refElement.position().top + refElement.height() >= scrollPos - 150) {
            $('.navbar-nav ul li a').removeClass('active');
            currLink.addClass('active');
          } else {
            currLink.removeClass('active');
          }
        }
      });
    }
  }

}
