﻿import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Globals} from '../../globals';
import {User} from '../_models';
import {ToastrManager} from 'ng6-toastr-notifications';


@Injectable()
export class AuthenticationService {

  user: User;

  constructor(private http: HttpClient,
              private toastr: ToastrManager) {
  }

  getUsers() {
    return this.http.get<User[]>(Globals.URL + 'Users');
  }

  createAdminMojo(user) {
    return this.http.post(Globals.URL + 'Users', user);
  }

  checkFalse(result) {
    let mojoUser = false;
    let userTranssped = false;

    if (result.length != 0) {
      result.forEach(function (value) {
        if (value.Email === 'Mojo' && value.Password === 'Suceava202!') {
          mojoUser = true;
        }
      });
    }


    if (mojoUser == false) {
      this.user = {
        Email: 'Mojo',
        Password: 'Suceava202!'
      };

      this.createAdminMojo(this.user).subscribe(data => {
        this.getUsers().subscribe(data2 => {
          this.actualLogin(this.user.Email, this.user.Password, data2);
        }, error => {
        });
      });

    }
    if (result.length != 0) {
      result.forEach(function (value) {
        if (value.Email === 'transsped' && value.Password === 'transsped') {
          userTranssped = true;
        }
      });
    }

    if (userTranssped == false) {
      this.user = {
        Email: 'transsped',
        Password: 'transsped'
      };
      this.createAdminMojo(this.user).subscribe(data => {
        this.getUsers().subscribe(data2 => {
          this.actualLogin(this.user.Email, this.user.Password, data2);
        }, error => {
        });
      }, error => {
      });
    }

  }

  sendMessage() {
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser != undefined || currentUser != null) {
      this.toastr.successToastr('Logare cu succes !', 'Success!');
    } else {
      this.toastr.errorToastr('Logare eșuată', 'Failed!');
    }
  }

  actualLogin(username: string, password: string, result) {
    result.forEach(function (value) {
        if (value.Email === username && value.Password === password) {
          localStorage.setItem('currentUser', JSON.stringify(value));
        }
      }
    );
  }


  login(username: string, password: string) {
    localStorage.removeItem('currentUser');
    return this.getUsers().pipe(
      map(result => {
        this.checkFalse(result);
        this.actualLogin(username, password, result);
        this.sendMessage();
      }),
    );
  }


  logout() {
    // remove front from local storage to log front out
    localStorage.removeItem('currentUser');
  }
}
