import { NgModule } from '@angular/core';

import { AdminRoutingModule } from './admin.routing.module';
import { AdminComponent } from './admin.component';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ng6-toastr-notifications';
import { AuthGuard } from './_guards';
import { AlertService, AuthenticationService } from './_services';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor, JwtInterceptor } from './_helpers';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { ImageUploadModule } from 'angular2-image-upload';
import { SwiperModule } from 'angular2-useful-swiper';
import { OrdersListComponent } from './orders/orders-list/orders-list.component';
import { OrdersViewComponent } from './orders/orders-view/orders-view.component';
import { ClientsListComponent } from './clients/clients-list/clients-list.component';
import { ClientsViewComponent } from './clients/clients-view/clients-view.component';
import { CertificateListComponent } from './certificates/certificate-list/certificate-list.component';
import { CertificateAddComponent } from './certificates/certificate-add/certificate-add.component';
import { OptionEditComponent } from './certificates/option-edit/option-edit.component';
import { ClientsEmailsComponent } from './clients/clients-emails/clients-emails.component';
import {SettingsComponent} from './settings/settings.component';

@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    ImageUploadModule.forRoot(),
    SwiperModule
  ],
  declarations: [
    AdminComponent,
    OrdersListComponent,
    OrdersViewComponent,
    ClientsListComponent,
    ClientsEmailsComponent,
    ClientsViewComponent,
    CertificateListComponent,
    CertificateAddComponent,
    OptionEditComponent,
    SettingsComponent
  ]
  , providers: [
    AuthGuard,
    AlertService,
    AuthenticationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class AdminModule {

}
