import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Certificate, Order, OrdersHistories} from '../_models';
import {Globals} from '../../globals';
import {formatDate} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  p_sign: any;

  constructor(private http: HttpClient) {
  }

  getOrders() {
    return this.http.get<Order[]>(Globals.URL + 'orders');
  }

  getOrdersHistories() {
    return this.http.get<OrdersHistories[]>(Globals.URL + 'OrdersHistories');
  }

  createOrder(order: Order) {
    return this.http.post(Globals.URL + 'orders', order);
  }

  // search order
  searchOrder(string: string) {
    return this.http.get<Order[]>(Globals.URL + 'searchOrder/' + string);
  }

  deleteOrder(id: number) {
    return this.http.delete(Globals.URL + 'orders/' + id);
  }

  getOrderById(id: number) {
    return this.http.get<Order>(Globals.URL + 'orders/' + id);
  }


  createPayment2(order: Order, time, randomString, pSign) {
    return Globals.URL3 + 'Romcard?' +
      'TERMINAL=' + Globals.TERMINAL +
      '&TRTYPE=' + 0 +
      '&ORDER=' + order.ORDER1 +
      '&AMOUNT=' + order.AMOUNT +
      '&CURRENCY=' + order.Currency +
      '&DESC=' + order.DESC +
      '&TIMESTAMP=' + time +
      '&NONCE=' + randomString +
      '&P_SIGN=' + pSign +
      '&MERCH_NAME=' + Globals.MERCH_NAME +
      '&MERCH_URL=' + Globals.MERCH_URL +
      '&MERCHANT=' + Globals.MERCHANT +
      '&EMAIL=' + Globals.EMAIL +
      '&BACKREF=' + Globals.BACKREF2;
  }

  generatePSign(order, time, randomString, trytipe) {
    // const amount = order.AMOUNT + '.00';
    const amount = order.AMOUNT;
    const key = '2B36F936D90C67A075AEF341D4B7A511';
    const text =
      amount.toString().length + amount.toString() +
      order.Currency.toString().length + order.Currency +
      order.ORDER1.toString().length + order.ORDER1.toString() +
      order.DESC.toString().length + order.DESC.toString() +
      Globals.MERCH_NAME.toString().length + Globals.MERCH_NAME.toString() +
      Globals.MERCH_URL.toString().length + Globals.MERCH_URL.toString() +
      Globals.MERCHANT.toString().length + Globals.MERCHANT.toString() +
      Globals.TERMINAL.toString().length + Globals.TERMINAL.toString() +
      Globals.EMAIL.toString().length + Globals.EMAIL.toString() +
      trytipe.toString().length + trytipe.toString() +
      '-' +
      '-' +
      time.toString().length + time.toString() +
      randomString.toString().length + randomString.toString() +
      Globals.BACKREF2.length + Globals.BACKREF2.toString();
    localStorage.setItem('text', text);
    return this.http.post(Globals.URL + 'P_SIGN', {
      Key: key,
      Value: text
    });
  }

  generatePSignReversal(order, time, randomString, trytipe) {
    // order.OrdersHistories[0].INT_REF = 'AD46FAACB03FE01E';
    // const amount = order.AMOUNT + '.00';
    const DESC = 'anulare comandă';
    // const amount = T;
    const key = '2B36F936D90C67A075AEF341D4B7A511';
    const text =
      order.OrdersHistories[0].ORDER.toString().length + order.OrdersHistories[0].ORDER.toString() +
      order.OrdersHistories[0].AMOUNT.toString().length + order.OrdersHistories[0].AMOUNT.toString() +
      order.OrdersHistories[0].CURRENCY.toString().length + order.OrdersHistories[0].CURRENCY +
      order.OrdersHistories[0].RRN.toString().length + order.OrdersHistories[0].RRN.toString() +
      order.OrdersHistories[0].INT_REF.toString().length + order.OrdersHistories[0].INT_REF.toString() +
      trytipe.toString().length + trytipe.toString() +
      Globals.TERMINAL.toString().length + Globals.TERMINAL.toString() +
      time.toString().length + time.toString() +
      randomString.toString().length + randomString.toString() +
      Globals.BACKREF2.length + Globals.BACKREF2.toString();
    localStorage.setItem('text', text);
    return this.http.post(Globals.URL + 'P_SIGN', {
      Key: key,
      Value: text
    });
  }

  revertOrder(order: Order, time, randomString, pSign) {
    // order.OrdersHistories[0].INT_REF = 'AD46FAACB03FE01E';
    return Globals.URL3 + 'Romcard?' +
      '&AMOUNT=' + order.OrdersHistories[0].AMOUNT +
      '&CURRENCY=' + order.OrdersHistories[0].CURRENCY +
      '&ORDER=' + order.Id +
      '&RRN=' + order.OrdersHistories[0].RRN +
      '&INT_REF=' + order.OrdersHistories[0].INT_REF +
      '&TRTYPE=' + 24 +
      '&TERMINAL=' + Globals.TERMINAL +
      '&TIMESTAMP=' + time +
      '&NONCE=' + randomString +
      '&BACKREF=' + Globals.BACKREF2 +
      '&P_SIGN=' + pSign;
  }

  generateRandomString() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 32; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  VAT(value: any, value2: any) {
    return this.http.post(Globals.URL + 'VAT', {
      CountryCode: value2,
      VATNumber: value
    });
  }
}
