import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService, OrdersService } from '../../_services';
import { Client, Countries, Order } from '../../_models';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-clients-emails',
  templateUrl: './clients-emails.component.html',
  styleUrls: ['../clients-emails/clients-emails.component.scss']
})
export class ClientsEmailsComponent implements OnInit {
  // Variabile pentru inserare date
  clients: Client[];
  filteredClients: Client[];

  // Variabile cautare
  private _searchTerm: string;

  // pagination curent page
  // public p: number;
  clientsArray = [];

  itemsOnPage = 20;
  pageNumber = 1;

  constructor(
    private router: Router,
    private orderService: OrdersService,
    private clientService: ClientService,
    private resolver: ComponentFactoryResolver,
    private http: HttpClient
  ) {
  }

  ngOnInit() {
    this.getClients();
  }

  getClients() {
    this.clientService.getClients().subscribe(data => {
      this.clients = data;
      this.filteredClients = this.clients;
      this.clientsArray = Array.from(new Set(this.filteredClients.map((item: any) => item.Email)));
    });
  }

  getEmail(item): void {
    localStorage.removeItem('getEmail');
    localStorage.setItem('getEmail', item);
    this.router.navigate(['admin/clients-list']);
  }

  // Cautari

  get searchTerm(): string {
    return this._searchTerm;
  }

  set searchTerm(value: string) {
    this._searchTerm = value;
    this.filteredClients = this.searchClientsByName2(value);
    this.clientsArray = Array.from(new Set(this.filteredClients.map((item: any) => item.Email)));
  }

  searchClientsByName2(searchString: string) {
    if (searchString != '') {
      return this.clients.filter(
        client =>
          client.Email != null && client.Email.toLowerCase().indexOf(searchString.toLowerCase()) !== -1);
    } else {
      return this.clients;
    }
  }

  callculateItemNumber(x, y, z) {
    return ((x * (y - 1)) + z + 1);
  }
}
