import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './_services';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
  }

  logout() {
    if (confirm('Sunteți sigur că doriți să vă delogați?')) {
      window.location.reload();
      this.authenticationService.logout();
      this.router.navigate(['admin']);
    }
  }

}
