import { ContactUsComponent } from './contactUs/contactUs.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FrontRoutingModule } from './front.routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ClickOutsideModule } from 'ng-click-outside';
import { BuyCertificateComponent } from './buyCertificate/buyCertificate.component';
import { RepositoryComponent } from './repository/repository.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { NavbarComponent } from './navbar/navbar.component';
import { NewsComponent } from './news/news.component';
import { FooterComponent } from './footer/footer.component';
import { CasesComponent } from './cases/cases.component';
import { OtpComponent } from './otp/otp.component';
import { PrivacyComponent } from './privacy/privacy.component';

@NgModule({
  imports: [
    CommonModule,
    FrontRoutingModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    DragScrollModule,
    ScrollToModule.forRoot()
  ],
  declarations: [
    HomeComponent,
    BuyCertificateComponent,
    RepositoryComponent,
    NavbarComponent,
    NewsComponent,
    FooterComponent,
    CasesComponent,
    OtpComponent,
    PrivacyComponent,
    ContactUsComponent
  ],
  exports: [],
  providers: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FrontModule {
  getState(outlet) {
    return outlet.activatedRouteData.state;
  }
}
