export class Certificate {
  Id: number;
  Name: string;
  Support: string;
  Price1: number;
  Price2: number;
  Price3: number;
  Certificate_options: string;
  IsDeleted: boolean;
  CreatedAt: string;
  UpdatedAt: string;
}


