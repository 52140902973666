import {Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ClientService, OrdersService} from '../../_services';
import {Client, Countries, Order} from '../../_models';
import {HttpClient} from '@angular/common/http';
import {Location} from '@angular/common';

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.scss']
})
export class ClientsListComponent implements OnInit {
  // Vector tip de client
  clientTypeArray = [
    {value: -1, text: 'Toti'},
    {value: 0, text: 'PF'},
    {value: 1, text: 'PJ-EU'},
    {value: 2, text: 'PJ-NonEU'}
  ];

  // pagination
  long: number;
  curentPage = 1;
  itemsOnPage = 4;

  // Variabile pentru inserare date
  orders: Order[];
  clients: Client[];
  filteredClients: Client[];

  // Variabile sortare
  sortName = false;
  sortId = true;

  // Variabile cautare
  private _searchTerm: string;

  // Variabile filtrare
  private _filterType: number;
  private _filterCountry: string;

  // Variabila tari
  countries: Countries[];

  clientsLength: number;
  filteredClientsLength: number;

  // pagination curent page
  public p: number;
  clientsArray = [];

  constructor(
    private router: Router,
    private orderService: OrdersService,
    private clientService: ClientService,
    private resolver: ComponentFactoryResolver,
    private http: HttpClient,
    private _location: Location
  ) {
  }

  ngOnInit() {
    this.getOrders();
    this.getClients();
    this.getCountries();
  }

  // Navigatori + aduc date din database

  backClicked() {
    this._location.back();
  }

  getCountries() {
    this.getJSON().subscribe(data => {
      this.countries = data;
    });
  }

  getJSON() {
    return this.http.get<Countries[]>('./assets/countries.json');
  }

  getClients() {
    this.clientService.getClients().subscribe(data => {
      this.clients = data.filter(client => client.Email == localStorage.getItem('getEmail'));
      this.filteredClients = this.clients;
      this.clientsLength = this.clients.length;
      this.filteredClientsLength = this.filteredClients.length;
      this.sortType('id');
    });
  }

  getOrders() {
    this.orderService.getOrders().subscribe(data => {
      this.orders = data;
      this.long = this.orders.length;
    });
  }

  deleteClient(client: Client): void {
    if (confirm('Sunteți sigur că doriți să ștergeți clientul?')) {
      this.clientService.deleteClient(client.Id).subscribe(data => {
        this.filteredClients = this.filteredClients.filter(u => u !== client);
      });
    }
  }

  viewOrder(order: Order): void {
    localStorage.removeItem('viewOrderId');
    localStorage.setItem('viewOrderId', order.Id.toString());
    this.router.navigate(['admin/orders-view']);
  }

  viewClient(client: Client): void {
    localStorage.removeItem('viewClientId');
    localStorage.setItem('viewClientId', client.Id.toString());
    this.router.navigate(['admin/clients-view']);
  }


  // Filtrari

  get filterType(): number {
    return this._filterType;
  }

  set filterType(value: number) {
    this._filterType = value;
    this.filteredClients = this.filterClientByType(value);
  }

  filterClientByType(Type: number) {
    this._searchTerm = '';
    this._filterCountry = '';

    this.p = 1;
    if (Type == -1) {
      return this.clients.filter(client => client.Type != Type);
    } else {
      return this.clients.filter(client => client.Type == Type);
    }
  }

  filterClientByEmail(email) {
    this._searchTerm = '';
    this._filterCountry = '';

    this.p = 1;
    // if (Type == -1) {
    return this.clients.filter(client => client.Email == email);
    // } else {
    //   return this.clients.filter(client => client.Type == Type);
    // }
  }

  get filterCountry(): string {
    return this._filterCountry;
  }

  set filterCountry(value: string) {
    this._filterCountry = value;
    this.filteredClients = this.filterClientByCountry(value);
  }

  filterClientByCountry(country: string) {
    this._searchTerm = '';
    this._filterType = -1;

    if (country == 'None' || country == '') {
      return this.clients.filter(client => client.Country != country);
    } else {
      return this.clients.filter(client => client.Country == country);
    }
  }

  // Cautari

  get searchTerm(): string {
    return this._searchTerm;
  }

  set searchTerm(value: string) {
    this._searchTerm = value;
    this.filteredClients = this.searchClientsByName(value);
  }

  searchClientsByName(searchString: string) {
    this._filterType = -1;
    this._filterCountry = '';

    if (searchString != '') {
      return this.clients.filter(
        client =>
          client.Name != null && client.Name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
          client.Surname != null && client.Surname.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
          client.CompanyName != null && client.CompanyName.toLowerCase().indexOf(searchString.toLowerCase()) !== -1);
    } else {
      return this.clients;
    }
  }


  // Sortari

  sortType(sort: string) {
    if (sort === 'name' && this.sortName === false) {
      this.sortName = true;
      this.filteredClients = this.filteredClients.sort(
        this.sortByClientNameAsc
      );
    } else if (sort === 'name' && this.sortName === true) {
      this.sortName = false;
      this.filteredClients = this.filteredClients.sort(
        this.sortByClientNameDesc
      );
    }
    if (sort === 'id' && this.sortId === false) {
      this.sortId = true;
      this.filteredClients = this.filteredClients.sort(this.sortByClientIdAsc);
    } else if (sort === 'id' && this.sortId === true) {
      this.sortId = false;
      this.filteredClients = this.filteredClients.sort(this.sortByClientIdDesc);
    }
  }

  sortByClientNameAsc(c1: Client, c2: Client) {
    if (c1.Name > c2.Name || c1.CompanyName > c2.CompanyName) {
      return 1;
    } else if (c1.Name === c2.Name) {
      return 0;
    } else {
      return -1;
    }
  }

  sortByClientNameDesc(c1: Client, c2: Client) {
    if (c1.Name < c2.Name || c1.CompanyName < c2.CompanyName) {
      return 1;
    } else if (c1.Name === c2.Name) {
      return 0;
    } else {
      return -1;
    }
  }

  sortByClientIdAsc(c1: Client, c2: Client) {
    return c1.Id - c2.Id;
  }

  sortByClientIdDesc(c1: Client, c2: Client) {
    return c2.Id - c1.Id;
  }
}
