import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../globals';
import {Settings} from '../_models/settings';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) {
  }

  getSettings() {
    return this.http.get<Settings>(Globals.URL + 'InvoiceConfigurations');
  }

  updateSettings(settings: Settings) {
    return this.http.put(Globals.URL + 'InvoiceConfigurations/' + settings.Id, settings);
  }

  createSettings(settings: Settings) {
    return this.http.post(Globals.URL + 'InvoiceConfigurations/', settings);
  }
}
