import {Component, HostListener, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Certificate, Countries} from '../../admin/_models';
import {CertificateService, ClientService, OrdersService} from '../../admin/_services';
import {HttpClient} from '@angular/common/http';
import {formatDate} from '@angular/common';
import {ToastrManager} from 'ng6-toastr-notifications';

@Component({
  selector: 'app-buy-certificate',
  templateUrl: './buyCertificate.component.html',
  styleUrls: ['./buyCertificate.component.scss'],
})
export class BuyCertificateComponent implements OnInit {

  buyCertificateForm: FormGroup;
  buyCertificateId: string;
  certificate: Certificate;
  pSign: any;

  // tslint:disable-next-line:no-inferrable-types
  personType: number = 0;
  price: number;
  totalPrice: any;
  VATPercentage: number;
  VATAmount: any;
  orderValue: string;
  // Variabila tari
  countries: Countries[];
  errorData = [];
  personTypeArray = [
    {value: 0, text: 'Private person'},
    {value: 1, text: 'Company - EU'},
    {value: 2, text: 'Company Non-EU'}
  ];
  selectedPrice;
  initSelectedPrice = true;
  VATRates = 0.19;
  validVAT = true;
  validVAT2 = null;
  validVAT3 = null;
  VATCodeVariable: any;
  countryVariable: any;

  constructor(private formBuilder: FormBuilder,
              private certificateService: CertificateService,
              private orderService: OrdersService,
              private clientService: ClientService,
              private http: HttpClient,
              public toastr: ToastrManager) {
  }

  ngOnInit() {
    this.getCountries();
    this.initBuyCertificateForm(this.personType);
    this.buyCertificateId = localStorage.getItem('buyCertificateId');
    this.getCertificatePrices(this.buyCertificateId);
  }

  initBuyCertificateForm(formType) {

    formType = parseInt(formType, 10);

    if (formType === 0) {
      this.validVAT = true;
      this.buyCertificateForm = this.formBuilder.group({
        Name: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
        Surname: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
        UniqueIdentificationNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(13)]],
        Country: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
        Street: ['', Validators.required],
        Number: ['', [Validators.required]],
        AddressDetails: [],
        Email: ['', [Validators.email, Validators.required]],
        CompanyName: [],
        ContactName: [],
        VATCode: [],
        FiscalIdentificationNumber: [],
        AMOUNT: [],
        Currency: [],
        Certificate_Id: [],
        ORDER1: [],
        DESC: [],
        Phone: ['', [Validators.required, Validators.pattern('^[0-9 +]*$')]],
        IBAN: [],
        Bank: [],
        Type: [],
        Client_id: [],
        VATAmount: [],
        VATPercentage: [19],
        City: ['-'],
      });
      this.calculateTotalPrice();
    }

    if (formType === 1) {
      this.validVAT = null;
      this.buyCertificateForm = this.formBuilder.group({
        Name: [''],
        Surname: [''],
        UniqueIdentificationNumber: [''],
        Country: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
        Street: ['', Validators.required],
        Number: ['', [Validators.required]],
        AddressDetails: [],
        Email: ['', [Validators.email, Validators.required]],
        CompanyName: ['', Validators.required],
        ContactName: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
        VATCode: [''],
        FiscalIdentificationNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        AMOUNT: [],
        Currency: [],
        Certificate_Id: [],
        ORDER1: [],
        DESC: [],
        Phone: ['', [Validators.required, Validators.pattern('^[0-9 +]*$')]],
        IBAN: ['', [Validators.required, Validators.maxLength(34), Validators.pattern('^[a-zA-Z0-9]*$')]],
        Bank: ['', [Validators.required]],
        Type: [],
        Client_id: [],
        VATAmount: [],
        VATPercentage: [],
        City: ['-'],
      });
      this.calculateTotalPrice();
    }

    if (formType === 2) {
      this.validVAT = true;
      this.buyCertificateForm = this.formBuilder.group({
        Name: [],
        Surname: [],
        UniqueIdentificationNumber: [],
        Country: [],
        Street: [],
        Number: [],
        AddressDetails: [],
        Email: ['', [Validators.email, Validators.required]],
        CompanyName: ['', Validators.required],
        ContactName: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
        VATCode: [],
        FiscalIdentificationNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        AMOUNT: [],
        Currency: [],
        Certificate_Id: [],
        ORDER1: [],
        DESC: [],
        Phone: ['', [Validators.required, Validators.pattern('^[0-9 +]*$')]],
        IBAN: ['', [Validators.required, Validators.maxLength(34), Validators.pattern('^[a-zA-Z0-9]*$')]],
        Bank: ['', [Validators.required]],
        Type: [],
        Client_id: [],
        VATAmount: [],
        VATPercentage: [],
        City: ['-'],
      });
      this.calculateTotalPrice();
    }

  }

  getCountries() {
    this.getJSON().subscribe(data => {
      this.countries = data;
    });
  }

  getJSON() {
    return this.http.get<Countries[]>('./assets/countries.json');
  }

  get Bank() {
    return this.buyCertificateForm.get('Bank');
  }

  get VATCode() {
    return this.buyCertificateForm.get('VATCode');
  }

  get IBAN() {
    return this.buyCertificateForm.get('IBAN');
  }

  get FiscalIdentificationNumber() {
    return this.buyCertificateForm.get('FiscalIdentificationNumber');
  }

  get CompanyName() {
    return this.buyCertificateForm.get('CompanyName');
  }

  get ContactName() {
    return this.buyCertificateForm.get('ContactName');
  }

  get Name() {
    return this.buyCertificateForm.get('Name');
  }

  get Email() {
    return this.buyCertificateForm.get('Email');
  }

  get Phone() {
    return this.buyCertificateForm.get('Phone');
  }

  get AddressDetails() {
    return this.buyCertificateForm.get('AddressDetails');
  }

  get StreetNumber() {
    return this.buyCertificateForm.get('Number');
  }

  get Street() {
    return this.buyCertificateForm.get('Street');
  }

  get Surname() {
    return this.buyCertificateForm.get('Surname');
  }

  get AMOUNT() {
    return this.buyCertificateForm.get('AMOUNT');
  }

  get UniqueIdentificationNumber() {
    return this.buyCertificateForm.get('UniqueIdentificationNumber');
  }

  get Country() {
    return this.buyCertificateForm.get('Country');
  }

  get City() {
    return this.buyCertificateForm.get('City');
  }

  onChangePersonType(personType) {
    this.personType = personType;
    this.initBuyCertificateForm(this.personType);
  }

  onChangePrice(price) {
    this.selectedPrice = parseInt((<HTMLSelectElement>document.getElementById('selectPrice')).value, 10);
    this.price = price;
    this.initSelectedPrice = false;
  }

  getCertificatePrices(certificate_id) {
    this.certificateService.getCertificateById(certificate_id)
      .subscribe(data => {
        this.certificate = data;

        const checkPrice = parseInt(localStorage.getItem('certificatePrice'), 10);
        localStorage.removeItem('certificatePrice');
        if (checkPrice !== undefined) {
          if (checkPrice === 1) {
            this.price = data.Price1;
          } else if (checkPrice === 2) {
            this.price = data.Price2;
          } else if (checkPrice === 3) {
            this.price = data.Price3;
          }
        }

        this.calculateTotalPrice();
      });
  }

  setOrderValue(id) {
    localStorage.setItem('OrderNumber', id);
    return id;
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.buyCertificateForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  checkVAT() {
    this.validVAT2 = null;
    if (this.VATCodeVariable != '' && this.VATCodeVariable != undefined) {
      this.orderService.VAT(this.VATCodeVariable, this.countryVariable).subscribe(data1 => {
          // @ts-ignore
          this.VATRates = data1.VATRates;
          // @ts-ignore
          this.validVAT = data1.IsValid;
          // @ts-ignore
          this.buyCertificateForm.controls['CompanyName'].setValue(data1.Name);
          // @ts-ignore
          this.buyCertificateForm.controls['Street'].setValue(data1.Address);
          this.calculateTotalPrice();
        },
        error => {
          this.validVAT = false;
          this.VATRates = 0.19;
          this.calculateTotalPrice();
        });
    } else {
      this.validVAT = undefined;
      this.VATRates = 0.19;
      this.calculateTotalPrice();
    }


  }

  calculateTotalPrice() {
    if (this.price != undefined) {
      const VATAmount = parseFloat(this.price.toString()) * parseFloat(this.VATRates.toString());
      this.VATAmount = VATAmount.toFixed(2);
      const totalPrice = parseFloat(this.price.toString()) + parseFloat(this.VATAmount.toString());
      this.totalPrice = totalPrice.toFixed(2);
    }
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.calculateTotalPrice();
  }

  @HostListener('document:click', ['$event'])
  onClick() {
    this.calculateTotalPrice();
  }

  onSubmit() {
    // this.checkVAT();
    // stop here if form is invalid
    if (this.buyCertificateForm.invalid) {
      this.toastr.errorToastr('Invalid input', 'Error!');
      return;
    } else {
      this.validVAT = false;
      this.buyCertificateForm.controls['Certificate_Id'].setValue(this.buyCertificateId);
      this.buyCertificateForm.controls['Type'].setValue(this.personType);
      this.buyCertificateForm.controls['Currency'].setValue('EUR');
      this.buyCertificateForm.controls['AMOUNT'].setValue(this.totalPrice);
      this.buyCertificateForm.controls['DESC'].setValue('sunt o descriere');
      this.buyCertificateForm.controls['VATAmount'].setValue(this.VATAmount);
      this.buyCertificateForm.controls['VATPercentage'].setValue(this.VATRates);

      this.clientService.createClient(this.buyCertificateForm.value).subscribe(data1 => {
        // @ts-ignore
        this.buyCertificateForm.controls['Client_id'].setValue(data1.Id);
        this.orderService.createOrder(this.buyCertificateForm.value)
          .subscribe(data2 => {
              // @ts-ignore

              this.buyCertificateForm.controls['ORDER1'].setValue(this.setOrderValue(data2.Id));
              // @ts-ignore
              localStorage.setItem('OrderNumber', data2.Id);

              const time = formatDate(Date.now(), 'yyyyMMddhhmmss', 'en-GB', '+0000');
              const randomString = this.orderService.generateRandomString();
              this.orderService.generatePSign(this.buyCertificateForm.value, time, randomString, 0).subscribe(
                data3 => {
                  // @ts-ignore
                  this.pSign = data3.Result;
                  // @ts-ignore
                  // tslint:disable-next-line:max-line-length
                  window.location.href = this.orderService.createPayment2(this.buyCertificateForm.value, time, randomString, this.pSign).toString();
                },
                error => {
                });
            },
            error => {
              this.errorData = [];
              this.errorData.push(error);
            });
      }, error => {
        this.errorData = [];
        this.errorData.push(error);
      });
    }
  }

  selectVATCode(vat) {
    if (vat != 0) {
      this.validVAT2 = false;
      this.VATRates = 0.19;
    } else if (vat == 0) {
      this.validVAT2 = null;
      this.validVAT = null;
    }
    this.VATCodeVariable = vat;
  }

  selectCountry(country) {
    this.validVAT3 = true;
    this.countryVariable = country;
  }
}
