export class Order {
  Id: number;
  AMOUNT: number;
  Amount: number;
  Currency: string;
  RRN: string;
  INT_REF: string;
  TRYTPE: number;
  TERMINAL: string;
  TIMESTAMP: number;
  NONCE: string;
  BACKREF: string;
  P_SIGN: string;
  ACTION: string;
  ORDER1: number;
  DESC: string;
  APPROVAL: number;
  MESSAGE: string;
  Client_id: number;
  Pdf: string;
  Pdf2: string;
  Certificate_Id: number;
  VATAmount: string;
  VATPercentage: string;
  OrdersHistories = new OrdersHistories();
  }

export class OrdersHistories {
  ACTION: string;
  AMOUNT: number;
  APPROVAL: string;
  BACKREF: string;
  COUNTRY: string;
  CURRENCY: string;
  CreatedAt: string;
  DESC: string;
  EMAIL: string;
  INT_REF: string;
  Id: number;
  MERCH_GMT: string;
  MERCH_NAME: string;
  MERCH_URL: string;
  MESSAGE: string;
  NONCE: string;
  ORDER: number;
  P_SIGN: string;
  RC: string;
  RRN: string;
  TERMINAL: string;
  TIMESTAMP: string;
  TRTYPE: string;
  UpdatedAt: string;
}
